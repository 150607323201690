<template>
  <div id="petition">
    <v-container
      class="white--text mt-6"
      style="max-width: 640px;"
      v-if="!complete"
    >
      <div class="text-h4" style="letter-spacing: 0 !important;">
        Save the Old Foundry
      </div>
      <div
        class="text-h5 font-weight-bold mostly-white"
        style="letter-spacing: 1px !important;"
      >
        Sign this petition and let them know you care!
      </div>

      <p class="petition-text mt-4">
        Plymouth is seeing an influx of decisions that is slowly erasing its
        charm and character. But we can move forward without demolishing our
        history. Let Safe Harbor Marinas and Town officials know that you care
        about Plymouth and its future!
      </p>
      <v-text-field
        class="mt-8"
        solo
        v-model="fName"
        :error-messages="fNameErrors"
        label="First name"
        required
        @input="$v.fName.$touch()"
        @blur="$v.fName.$touch()"
      ></v-text-field>
      <v-text-field
        solo
        class="mt-2"
        v-model="lName"
        :error-messages="lNameErrors"
        label="Last name"
        required
        @input="$v.lName.$touch()"
        @blur="$v.lName.$touch()"
      ></v-text-field>
      <v-text-field
        solo
        class="mt-2"
        v-model="email"
        :error-messages="emailErrors"
        label="Email address"
        required
        v-on:keyup.enter="slideNext"
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
      ></v-text-field>

      <p class="petition-text mb-0">
        Do you live in Plymouth?
      </p>
      <v-radio-group v-model="liveHere" @change="changeRadio" class="mt-0">
        <v-radio
          dark
          label="Yes, I live in Plymouth"
          :value="true"
          @click="
            city = 'Plymouth';
            state = 'MA';
          "
        ></v-radio>
        <v-radio
          dark
          label="No, but I really care about this issue!"
          :value="false"
          @click="
            city = '';
            state = '';
          "
        ></v-radio>
      </v-radio-group>
      <v-expand-transition v-if="this.liveHere === true">
        <div class="petition-text">
          <v-text-field
            solo
            class="mt-2"
            v-model="address"
            label="Street address"
            required
            v-on:keyup.enter="slideNext"
            :error-messages="addressErrors"
            @input="$v.address.$touch()"
            @blur="$v.address.$touch()"
          ></v-text-field>
          <v-row class="mt-0">
            <v-col cols="8">
              <v-text-field
                solo
                v-model="city"
                label="City"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                solo
                v-model="state"
                label="State"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
      <v-expand-transition v-if="this.liveHere === false">
        <v-row class="mt-0">
          <v-col cols="8">
            <v-text-field
              solo
              v-model="city"
              label="City"
              required
              :error-messages="cityErrors"
              @input="$v.city.$touch()"
              @blur="$v.city.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              solo
              v-model="state"
              label="State"
              required
              :error-messages="stateErrors"
              @input="$v.state.$touch()"
              @blur="$v.state.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-checkbox dark x-large v-model="addToMailingList" :value="true">
        <template v-slot:label>
          <h2 :class="addToMailingList ? 'white--text' : ''">
            Please let me know what happens next with the Old&nbsp;Foundry!
          </h2>
        </template>
      </v-checkbox>

      <div class="pt-5 pb-10">
        <v-btn
          color="#03045E"
          block
          dark
          x-large
          :disabled="submitDisabled"
          @click="submit()"
        >
          submit
          <v-icon dark class="ml-2">mdi-send-circle</v-icon>
        </v-btn>
      </div>
    </v-container>
    <v-container
      class="white--text mt-6"
      style="max-width: 640px;"
      v-if="complete"
    >
      <div class="text-h4" style="letter-spacing: 0 !important;">
        Save the Old Foundry
      </div>
      <div
        class="text-h5 font-weight-bold mostly-white"
        style="letter-spacing: 1px !important;"
      >
        Thank you for signing the petition!
      </div>
      <p class="petition-text mt-4" v-if="addToMailingList !== true">
        To learn more about everything that's happening&mdash;and to see pictures of what the (ginormous Walmart-esque) Marina's plans would look like&mdash;there's oodles of information on our website:
      </p>
      <p class="petition-text mt-4" v-if="addToMailingList === true">
        You've been added to our mailing list&mdash;we'll keep you updated on what happens next. Thank you again!</p>
      <p class="petition-text mt-4" v-if="addToMailingList === true">
        In the meantime, to learn more about everything&mdash;and to see pictures of what the (ginormous Walmart-esque) Marina's plans would look like&mdash;there's oodles of information on our website:
      </p>
      <div>
        <v-btn
          color="#03045E"
          block
          dark
          x-large
          class="my-5"
          @click="openPC()"
        >
          plymouthcares.org
          <v-icon dark class="ml-2">mdi-open-in-new</v-icon>
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

const acUrl =
  "https://api.geoapify.com/v1/geocode/autocomplete" +
  "?bias=circle:-70.65423205074603,41.912404399787306,20000" +
  "&apiKey=8b8b4ff40531444280dd59f8d4a150d5&text=";
const requestOptions = {
  method: "GET"
};

const dbUrl = "https://pc-database.herokuapp.com";

const debounceDelay = 250;

export default {
  name: "Petition",
  data: () => ({
    ready: false,
    fName: "",
    lName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    select: null,
    liveHere: null,
    height: 320,
    slide: 0,
    acLoading: false,
    acDebouncing: false,
    acDebounceTimeout: null,
    acAddresses: [],
    search: null,
    emailMode: null,
    addToMailingList: false,
    complete: false
  }),
  watch: {
    search(val) {
      val && val !== this.address && this.autocomplete(val);
    }
  },
  mixins: [validationMixin],
  validations: {
    fName: { required },
    lName: { required },
    email: { required, email },
    address: { required },
    city: { required },
    state: { required }
  },
  computed: {
    fNameErrors() {
      const errors = [];
      if (this.$v.fName.$dirty) {
        if (!this.$v.fName.required) {
          errors.push("First name is required.");
        }
      }
      return errors;
    },
    lNameErrors() {
      const errors = [];
      if (this.$v.lName.$dirty) {
        if (!this.$v.lName.required) {
          errors.push("Last name is required.");
        }
      }
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.address.$dirty) return errors;
      !this.$v.address.required && errors.push("Address is required.");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("City is required.");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push("State is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid email");
      !this.$v.email.required && errors.push("Email is required");
      return errors;
    },
    submitDisabled() {
      if (this.liveHere === true) {
        return !!(
          !this.fName ||
          !this.lName ||
          !this.email ||
          !this.address ||
          this.emailErrors.length ||
          this.lNameErrors.length ||
          this.fNameErrors.length ||
          this.addressErrors.length
        );
      } else if (this.liveHere === false) {
        return !!(
          !this.fName ||
          !this.lName ||
          !this.email ||
          !this.city ||
          !this.state ||
          this.emailErrors.length ||
          this.lNameErrors.length ||
          this.fNameErrors.length ||
          this.cityErrors.length ||
          this.stateErrors.length
        );
      } else {
        return true;
      }
    },
    nextDisabled2() {
      if (this.liveHere === "no") {
        return false;
      } else if (this.liveHere === "yes" && this.address !== "") {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    changeRadio() {
      this.resizeTimeout();
      if (this.liveHere === "yes") {
        this.focus("address");
      }
    },
    focus(el) {
      if (this.$refs[el]) {
        this.$refs[el].focus();
      } else {
        setTimeout(() => {
          this.focus(el);
        }, 250);
      }
    },
    slideNext() {
      if (this.slide === 0 && this.nextDisabled1) {
        return false;
      }
      if (this.slide === 1 && this.nextDisabled2) {
        return false;
      }
      this.slide++;
      this.resizeTimeout();
    },
    async submit() {
      let address = `${this.city}, ${this.state}`;
      if (this.address) {
        address = `${this.address}, ${address}`;
      }
      const data = {
        fName: this.fName,
        lName: this.lName,
        email: this.email,
        address: address,
        plymouth: this.liveHere,
        addToMailingList: this.addToMailingList
      };
      const rawResponse = await fetch(dbUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      const content = await rawResponse.json();
      this.submitDone(content);
    },
    submitDone(result) {
      console.log(result);
      localStorage.setItem("petitionCompleted", this.fName);
      this.complete = true;
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    },
    resize() {
      if (this.$refs["card" + this.slide]?.$el) {
        const box = this.$refs["card" + this.slide].$el.getBoundingClientRect();
        this.height = box.height + 70;
      } else {
        setTimeout(this.resize, 250);
      }
    },
    resizeTimeout() {
      setTimeout(this.resize, 50);
      setTimeout(this.resize, 250);
      setTimeout(this.resize, 500);
    },
    close() {
      this.ready = false;
    },
    emailSignup() {
      console.log("email signup");
      this.emailMode = "signup";
      this.resizeTimeout();
    },
    emailDecline() {
      console.log("email decline");
      this.emailMode = "decline";
      this.resizeTimeout();
    },
    autocomplete(str) {
      if (!this.acDebouncing) {
        this.acDebouncing = true;
        this.acDebounceTimeout = setTimeout(() => {
          this.acDebouncing = false;
        }, debounceDelay);
        this.acDebounced(str);
      } else {
        clearTimeout(this.acDebounceTimeout);
        this.acDebounceTimeout = setTimeout(() => {
          this.acDebouncing = false;
          this.acDebounced(str);
        }, debounceDelay);
      }
    },
    acDebounced(str) {
      this.acLoading = true;
      fetch(acUrl + str, requestOptions)
        .then(response => response.json())
        .then(result => this.acProcess(result))
        .catch(error => console.log("error", error));
    },
    acProcess(obj) {
      this.acLoading = false;
      if (obj.features) {
        this.acAddresses = [];
        obj.features.forEach(feature => {
          const p = feature?.properties || {};
          const address = p.formatted
            .split(", United States of America")
            .join("");
          this.acAddresses.push(address);
        });
      }
    },
    openPC() {
      window.location.href = "https://plymouthcares.org";
    }
  },
  mounted() {
    window.$sap = this;
    this.ready = true;
    this.resize();
  }
};
</script>

<style lang="scss">
#petition {
  padding: 1em;
  font-size: 20px;
  background-color: #00b4d8;
  height: 100vh;
  overflow-y: auto;
  .v-carousel__controls {
    justify-content: start;
    width: 50%;
    pointer-events: none;
  }
  .v-input__slot {
    margin-bottom: 2px !important;
  }
  .error--text {
    color: #aa0000 !important;
    margin-top: 2px !important;
  }
}
.petition-text {
  color: #ffffffee;
  letter-spacing: 0;
  font-size: 1.2rem;
}
</style>
